@import "conf";

.container {

  position: relative;
  width:100%;
  height: $envelope-height;
  margin-top: $envelope-margin-top;

}

.envelope {
  position: relative;
  margin: auto;
  width: $envelope-width;
  height: $envelope-height;
}

.envelope-body-bg {
  position: absolute;
  //z-index: 10;
  z-index: -50;
  width: $envelope-width;
  height: $envelope-height;

  background-image: url('../images/envelope-body-bg.png');
  background-size: contain;
  background-repeat: no-repeat;
}

.envelope-body-front {
  position: absolute;
  //z-index: 30;
  z-index: -30;
  width: $envelope-width;
  height: $envelope-height;

  background-image: url('../images/envelope-body-front.png');
  background-size: contain;
  background-repeat: no-repeat;
}

.envelope-flip {
  position: absolute;
  //z-index: 40;
  z-index: 0;

  cursor: pointer;
  width: $envelope-width;
  height: $envelope-flip-height;

  transform-origin: 0 10px;
  transform-style: preserve-3d;
  -webkit-tap-highlight-color: transparent;
}

.envelope-flip-face {
  position: absolute;
  width: $envelope-width;
  height: $envelope-flip-height;

  display: flex;
  justify-content: center;
  backface-visibility: hidden;
  -webkit-backface-visibility: hidden;
}

.envelope-flip-face-front {
  background-image: url('../images/envelope-flip.png');
  background-size: contain;
  background-repeat: no-repeat;

  .cover-button {
    position: absolute;
    top: 30px;
  }
}

.envelope-flip-face-back {
  background-image: url('../images/envelope-flip.png');
  background-size: contain;
  background-repeat: no-repeat;
  transform: rotateY(180deg);
  .cover-button {
    position: absolute;
    top: 10px;
    //transform: rotateZ(180deg);
    transform: rotate3d(0,0,1,180deg);
    //font-weight: bold;
    color: #cd2908;
  }
}

.envelope-letter {
  position: absolute;
  z-index: -40;
  //width: $envelope-width - 2 * $letter-margin;
  //height: $envelope-height - 2 * $letter-margin;
  margin: $letter-margin;

  border-radius: 30px;

  overflow-y: auto;
  overflow-x: hidden;

  background-color: $text-background-color;
  background-image: url('../images/white-paperboard.png'), url('../images/letter-border.png');
  background-repeat: repeat, no-repeat;
  background-size: contain, auto;

  display: none;

  //padding: 29px 39px;
  padding: 18px 29px;
  height: 382px;
  width: 222px;
  overflow-wrap: break-word;

  color: #656567;
}

.envelope-text {
  //box-sizing:border-box;

  /*margin: 29px 39px;
  height: 362px;
  width: 202px;*/
  //width: $envelope-width - 2 * $letter-margin;
  min-height: $envelope-height / 2;
  //overflow-y: scroll;
  //margin: 50px 39px;
  /*
    p, h1, h2, h3, h4, h5, h6, table {
      margin-left: 5px;
      margin-right: 5px;

      img {
        margin-left: -5px;
        margin-right: -5px;
      }
    }*/
}

.envelope-body-front-title {
  position: absolute;
  margin: auto;
  bottom: 50px;
  width: 220px;
  height: 100px;
  left: 0;
  right: 0;
  overflow-wrap: break-word;
  overflow-y: hidden;
  overflow-x: hidden;
  display: flex;
  //justify-content: center;
  //color: #f2f2f2;
  white-space: pre;

  font-size: 1.5em;
  margin-block-start: 0.83em;
  margin-block-end: 0.83em;
  font-weight: bold;

  background-color: $text-background-color !important;
  background-image: url('../images/white-paperboard.png');
  background-repeat: repeat;
  p {
    margin: 10px;
  }
  //color: #fff100;
}

.envelope-open {
  animation: rotate-flip-up $open-flip-time cubic-bezier(0.250, 0.460, 0.450, 0.940) both;
}

.envelope-close {
  animation: rotate-flip-down $open-flip-time cubic-bezier(0.250, 0.460, 0.450, 0.940) both;
}

@keyframes rotate-flip-up {
  0% {
    transform: translateY(0) rotateX(0deg);
  }
  100% {
    transform: rotate3d(1, 0, 0, -180deg);
  }
}

@keyframes rotate-flip-down {
  0% {
    transform: rotate3d(1, 0, 0, -180deg);
  }
  100% {
    transform: translateY(0) rotateX(0deg);
  }
}

.letter-moved-up {
  animation: move-letter-up $move-letter-time;
  top: -$letter-shift;
}

.letter-moved-down {
  animation: move-letter-down $move-letter-time;
  top: 0;
}

@keyframes move-letter-up {
  0% {
    top: 0;
  }
  100% {
    top: -$letter-shift;
  }
}

@keyframes move-letter-down {
  0% {
    top: -$letter-shift;
  }
  100% {
    top: 0;
  }
}

.gift-load-error {
  background-color: $text-background-color !important;
  background-image: url('../images/white-paperboard.png');
  background-repeat: repeat;

  position: relative;
  margin: auto;
  max-width: 960px;
  padding: 24px;
  line-height: 2em;
}

.gift-full-error {
  font-size: smaller;
  max-height: 500px;
  overflow: scroll;
}
