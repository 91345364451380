@import url('https://fonts.googleapis.com/css?family=Roboto+Condensed');
@import "conf";
@import "gtranslate";

* {
  font-family:Roboto Condensed;
}

body {
  //background-color: rgb(43, 43, 43);
  background-image: url('../images/bg-gray.png');
}

.buttons-container {
  position: absolute;
  //bottom: 0;
  width: 100%;
  margin-top: $envelope-margin-top;
  z-index: 0;
}

.buttons-bar {
  position: relative;
  margin: auto;
  width: $envelope-width;
}

.ae-editable ::-moz-selection {
  background: $editor-selection-colors !important;
}

.ae-editable ::selection {
  background: $editor-selection-colors !important;
}

.home-page {
  ul, ol {
    //list-style-type: none;
    padding-left: 20px;
  }

  li, p {
    line-height: 1.5;
  }

  ul.credits {
    margin-top: -20px;
    list-style-type: none;
    padding-left: 0;

  }
}

a {
  color: #e7290f;
  font-weight: bold;
  text-decoration: none;

  &:hover {
    color: #b3200c;
    text-decoration: underline;
  }
}

