@import "conf";

a.gflag {
  vertical-align: middle;
  //font-size: 16px;
  font-size: 32px;
  padding: 1px 0;
  background-repeat: no-repeat;
  background-image: url('../images/flags32.png');
  //background-image: url(//gtranslate.net/flags/16.png);
  //background-image: url(//gtranslate.net/flags/32.png);
}

a.gflag img {
  border: 0;
}

a.gflag:hover {
  background-image: url('../images/flags32a.png');
  //background-image: url(//gtranslate.net/flags/32a.png);
  //background-image: url(//gtranslate.net/flags/16a.png);
}

#goog-gt-tt {
  display: none !important;
}

.goog-te-banner-frame {
  display: none !important;
}

.goog-te-menu-value:hover {
  text-decoration: none !important;
}
/*

body > :first-child {
  display:none;
}​
*/


body {
  position: static !important;
  min-height: 0 !important;
}

#google_translate_element2 {
  display: none !important;
}

.language-container {
  //margin-top: -30px;
  position: fixed;
  width: 100%;
  bottom: 0;
  background-color: gray;
  opacity: 0.8;

  display: flex;
  justify-content: center;
}

.language-selector {
  opacity: 1;
  a {
    color: black;
  }
}

.language-selector-flags {
  justify-content: center;
  display: flex;
  height: 34px;
  //height: 18px;
  a img {
    margin: 3px;

  }
}

.language-selector-select {

  justify-content: center;
  display: none;
  width: $envelope-width;
}

