
$envelope-proportions: 1.4375;
$envelope-width: 320px;
$envelope-height: $envelope-width * $envelope-proportions;
$envelope-flip-height: 544px;
$envelope-margin-top: 50px;
$letter-margin: 20px;

$letter-shift: $envelope-height - 20px;
$letter-width: $envelope-width - 2 * $letter-margin;
$letter-height: $letter-width * $envelope-proportions;

$open-flip-time: 0.4s;
$move-letter-time: 0.4s;

$editor-selection-colors: #aad0ee;

//$text-background-color: #f2f2f2;
$text-background-color: #f6f5db;
